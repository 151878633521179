import { H, Section } from "@jfrk/react-heading-levels";
import React from "react";

import {
  getArchiveURLPatternFromPageContext,
  getMainArchivePageTitleFromPageContext,
  getMainArchivePageLabelFromPageContext,
  getMainArchivePagePathFromPageContext,
  getYearArchivePageTitleFromPageContext,
  getYearArchivePageLabelFromPageContext,
  getYearArchivePagePathFromPageContext,
} from "../../shared/utils/postType";
import Archive, { StaticYearArchive } from "../components/Archive";
import Breadcrumb from "../components/Breadcrumb";
import ClientOnly from "../components/ClientOnly";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import { getBreadcrumbsHidden } from "../hooks/header";
import { PageContextProvider } from "../hooks/pageContext";
import { RekaiTrack } from "../hooks/rekai";

export default function YearArchivePageTemplate({ pageContext }) {
  const isBreadcrumbsHidden = getBreadcrumbsHidden();

  return (
    <PageContextProvider
      value={{
        ...pageContext,
      }}
    >
      <Layout>
        <RekaiTrack send={true} />
        <ClientOnly
          fallback={
            <>
              <SEO
                title={getYearArchivePageTitleFromPageContext(pageContext)}
              />
              {!isBreadcrumbsHidden && (
                <Breadcrumb
                  crumbs={[
                    {
                      label: getMainArchivePageLabelFromPageContext(
                        pageContext,
                      ),
                      url: getMainArchivePagePathFromPageContext(pageContext),
                    },
                    {
                      label: getYearArchivePageLabelFromPageContext(
                        pageContext,
                      ),
                      url: getYearArchivePagePathFromPageContext(pageContext),
                    },
                  ]}
                />
              )}
              <StaticYearArchive />
            </>
          }
        >
          <SEO title={getMainArchivePageTitleFromPageContext(pageContext)} />
          {!isBreadcrumbsHidden && (
            <Breadcrumb
              crumbs={[
                {
                  label: getMainArchivePageLabelFromPageContext(pageContext),
                  url: getMainArchivePagePathFromPageContext(pageContext),
                },
              ]}
            />
          )}
          <article className="c-article o-margin-top-large">
            <div className="o-grid">
              <div className="o-grid-row">
                <div className="o-grid-block o-grid-block--inherit">
                  <H className="c-article__title">
                    {getMainArchivePageTitleFromPageContext(pageContext)}
                  </H>
                  <Section>
                    <Archive
                      postType={pageContext.postTypeInfo.name}
                      urlPattern={getArchiveURLPatternFromPageContext(
                        pageContext,
                      )}
                    />
                  </Section>
                </div>
              </div>
            </div>
          </article>
        </ClientOnly>
      </Layout>
    </PageContextProvider>
  );
}
